import {flow, Instance, types} from 'mobx-state-tree';
import {SSOModel, User} from './models';
import {isDef} from '@lepicard/utils';
import {SSOConfig} from '@services/sso';

export interface ISSOStore extends Instance<typeof SSOModel> {}

export const SSOStore = types.optional(
  SSOModel.views((self) => {
    return {
      get selectedExtranetUser() {
        if (!isDef(self.user) || !isDef(self.user.extranetUsers)) {
          return null;
        }

        return self.user.extranetUsers.find((value: any) => {
          return value.id === self.user!.selectedExtranetUserId;
        });
      },
      get salesPeople() {
        return (
          this.selectedExtranetUser?.contact?.contactSalesPeople?.filter(
            (contact) => contact.salesPersonPurchaser && ['PA', 'PV'].includes(contact.prodActivityCode as string)
          ) ?? []
        );
      },
    };
  }).actions((self) => {
    return {
      setExpired(value: boolean) {
        self.sessionExpired = value;
      },
      setUser(user: ISSOStore['user']) {
        self.user = user;
      },
      retrieveAdditionalSSOInformation: flow(function* (token: string) {
        const serviceState = self.additionalInformationsState;
        serviceState.pending();

        try {
          const result = yield SSOConfig.fetchSSOInformation(token);
          self.user = result.data;
          setTimeout(() => {
            self.sessionExpired = true;
            console.log('Expired : reloading user data');
          }, SSOConfig.userCheckInterval * 60 * 1000);
          serviceState.done();
        } catch (error) {
          console.error('Cannot call user information', error);
          serviceState.fail(false);
        }
      }),
    };
  }),
  {
    sessionExpired: true,
  }
);
