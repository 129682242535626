import {string, object, boolean, number} from 'yup';

const validFrenchNumber = string()
  .matches(/^\d+(,\d+)?$/, 'Veuillez saisir une surface valide')
  .required('Veuillez saisir une surface');

const validCultureNumber = string()
  .matches(/^\d+(,\d+)?$/, 'VVeuillez saisir un nombre de culture valide')
  .required('Veuillez saisir un nombre de culture');

const validateDurabilityForm = object().shape({
  pacageNumber: string()
    .required('Veuillez saisir un numéro PACAGE')
    .test('numbers-only', 'Veuillez saisir un Pacage valide', (value) => {
      return /\d/.test(value);
    }),
  managerName: string()
    .required('Veuillez saisir le nom du gérant')
    .test('no-numbers', 'Veuillez saisir un nom valide', (value) => {
      return !/\d/.test(value);
    }),
  authorEmail: string()
    .required('Veuillez saisir un email')
    .test('valid-email', 'Veuillez saisir une adresse email valide', (value) => {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
    }),
  authorPhone: string()
    .required('Veuillez saisir un numéro de téléphone')
    .test('valid-phone', 'Le numéro de téléphone doit être composé de 10 chiffres', (value) => {
      return /^\d{10}$/.test(value);
    }),
  bleArea: validFrenchNumber,
  colzaArea: validFrenchNumber,
  bleDurability: validFrenchNumber,
  colzaDurability: validFrenchNumber,
  cultureLength: validCultureNumber,
  newAreas: boolean().required('Veuillez choisir une option'),
  statementType: number().required('Veuillez choisir une option'),
});

export default validateDurabilityForm;
